import { get } from '../helpers'

export default {
  fetchAveragePrices({ country, name, selectedDays }) {
    const path = `api/keywords/get_graph_data?country=${country}&name=${encodeURIComponent(
      name
    )}&type=average_prices&selectedDays=${selectedDays}`
    return get(path)
  }
}
