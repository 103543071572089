import { KEYWORD_SEARCH_VOLUME_URL } from 'constants/routes'
import { get } from '../helpers'

export default {
  fetchSearchVolume({ country, name, selectedDays }) {
    const path = `${KEYWORD_SEARCH_VOLUME_URL}?country=${country}&name=${encodeURIComponent(
      name
    )}&selectedDays=${selectedDays}`
    return get(path)
  }
}
