import { get } from '../helpers'

export default {
  fetchSeasonality({ country, name }) {
    const path = `api/keywords/get_graph_data?country=${country}&name=${encodeURIComponent(
      name
    )}&type=seasonality`
    return get(path)
  }
}
