import searchVolume from './search_volume'
import averagePrices from './average_prices'
import unitsSold from './units_sold'
import seasonality from './seasonality'

export default {
  ...searchVolume,
  ...averagePrices,
  ...unitsSold,
  ...seasonality
}
