import { post, destroy } from './helpers'

export function impersonateUser(data) {
  const path = 'api/admin/impersonate'
  return post(path, { data })
}

export function stopImpersonatingUser() {
  const path = 'api/admin/impersonate'
  return destroy(path)
}
