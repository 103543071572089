/* eslint-disable */
import 'whatwg-fetch'

import {
  GATED_FEATURE_EVENT,
  SET_FEATURE_LIMIT_EVENT,
  GATED_FEATURE_ERROR_TYPE,
  FEATURE_LIMIT_MAX_HEADER,
  FEATURE_LIMIT_COUNT_HEADER
} from 'constants/account/permissions'
import { getApiRoot } from 'helpers/api'
import { redirectToLogin } from 'helpers/redirects'
import { clearGlobalData } from 'helpers/storage'
import * as Cookies from '../cookies'

const FETCH_TIMEOUT = 25000 // 25 seconds - this is super high for the moment, we can adjust it over time
const FETCH_TIMEOUT_ERROR = 'FETCH_TIMEOUT'

// https://github.com/whatwg/fetch/issues/905#issuecomment-491970649
const wrapSignals = signals => {
  const controller = new AbortController()

  const onAbort = () => {
    controller.abort()

    for (const signal of signals) {
      signal.removeEventListener('abort', onAbort)
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      onAbort()
      break
    }
    signal.addEventListener('abort', onAbort)
  }

  return controller.signal
}

const makeRequest = async (path, args) => {
  const {
    data = {},
    extraHeaders = null,
    method,
    responseType = 'json',
    signal,
    multipart = false,
    timeout = FETCH_TIMEOUT,
    timeoutError = 'Whoops, looks like something went wrong, if the problem persists please contact Jungle Scout support.',
    featureLimitKey = null
  } = args

  // Grab auth token from cookie and send with API Request
  const auth_token = Cookies.getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)

  /**
   * IMPORTANT: Please don't add new headers here because it will break all .CN API requests
   * Please talk and coordinate with team Raven.
   */
  const headers = {
    Accept: '*/*',
    Authorization: `Bearer ${auth_token}`,
    'Client-Referer': document.URL,
    'Client-Timeout': timeout,
    ...extraHeaders
  }

  const isUploadingFile = data && !!data.file
  const isUploadingFiles = data && !!data.files

  if (!isUploadingFile && !isUploadingFiles) {
    headers['Content-Type'] = 'application/json'
  }

  // This is a hack to deal with fetch call when uploading multipart data.
  // Do not specify the content type - it will be taken care of.
  // https://stackoverflow.com/a/49510941/935651
  if (multipart) {
    delete headers['Content-Type']
  }

  // TODO: Regex to mach [&]
  // const url = apiRoot + '/' + path + `${path.match(/[?]/) ? '&' : '?'}country_code=US`
  const apiRoot = getApiRoot(path)
  const url = `${apiRoot}/${path}`

  const abortController = new AbortController()
  const signals = [abortController.signal]

  if (signal) {
    signals.push(signal)
  }

  const requestSignal = wrapSignals(signals)

  // The 'fetch' function only accepts a "body" property for POST requests, not GET
  const dataObj = {
    method,
    headers,
    signal: requestSignal
  }

  if (
    method.toUpperCase() === 'POST' ||
    method.toUpperCase() === 'PUT' ||
    method.toUpperCase() === 'PATCH' ||
    method.toUpperCase() === 'DELETE'
  ) {
    if (multipart) {
      dataObj.body = data
    } else if (isUploadingFile) {
      const fileData = new FormData()
      fileData.append('file', data.file)
      dataObj.body = fileData
    } else if (isUploadingFiles) {
      const dataBody = new FormData()
      Object.keys(data).forEach(key => {
        if (key === 'files') {
          data[key].forEach(file => {
            dataBody.append('files[]', file)
          })

          return
        }

        if (Array.isArray(data[key])) {
          data[key].forEach(value => {
            dataBody.append(`${key}[]`, value)
          })

          return
        }

        dataBody.append(key, data[key])
      })

      dataObj.body = dataBody
    } else {
      dataObj.body = JSON.stringify(data)
    }
  }

  let responseStatus
  // force requests to timeout after n seconds to save them hanging indefinitely
  const timeoutId = setTimeout(() => {
    abortController.abort()
    responseStatus = 408 // HTTP Timeout code
    if (window.Sentry) {
      window.Sentry.withScope(scope => {
        scope.setTag('api_url', url)
        scope.setLevel('warning')
        window.Sentry.captureMessage(FETCH_TIMEOUT_ERROR)
      })
    }
  }, timeout)

  try {
    const res = await fetch(url, dataObj)
    if (!responseStatus) {
      responseStatus = res.status
    }
    clearTimeout(timeoutId)

    if (res.status === 401) {
      clearGlobalData()
      Cookies.deleteAuthToken()
      redirectToLogin()
    } else if ([429, 503].includes(res.status)) {
      return {
        ok: false,
        status: res.status,
        error: await res.text()
      }
    } else if ([202, 204].includes(res.status)) {
      // no content
      return {
        ok: res.ok,
        status: res.status
      }
    }

    // For downloading and viewing invoices
    //
    // TODO:
    // Is there a cleaner way to handle this type of response?  Redundant double promise?
    let payload =
      responseType === 'blob'
        ? {
            data: await res.blob()
          }
        : await res.json()

    const featureLimits = extractLimitHeaders(res)

    if (featureLimitKey && featureLimits) {
      window.dispatchEvent(
        new CustomEvent(SET_FEATURE_LIMIT_EVENT, {
          detail: { featureLimitKey, featureLimits }
        })
      )
    }

    if (
      res.status === 403 &&
      payload?.error?.type === GATED_FEATURE_ERROR_TYPE
    ) {
      window.dispatchEvent(
        new CustomEvent(GATED_FEATURE_EVENT, {
          detail: { ...payload.error }
        })
      )
      return { ok: false, status: res.status, ...payload }
    }

    return {
      ...payload,
      ok: res.ok,
      status: res.status
    }
  } catch (e) {
    clearTimeout(timeoutId)
    console.error(e)
    return {
      ok: false,
      status: responseStatus,
      error: timeoutError
    }
  }
}

// Modifies the fetch function by: Adding access token and/or changing HTTP method
const get = (path, args = {}) => {
  return makeRequest(path, { ...args, method: 'get' })
}

const post = (path, args = {}) => {
  return makeRequest(path, { ...args, method: 'post' })
}

const put = (path, args = {}) => {
  return makeRequest(path, { ...args, method: 'put' })
}

const destroy = (path, args = {}) => {
  return makeRequest(path, { ...args, method: 'delete' })
}

const patch = (path, args = {}) => {
  return makeRequest(path, { ...args, method: 'PATCH' })
}

function extractLimitHeaders(response) {
  const limitCount = parseInt(response.headers.get(FEATURE_LIMIT_COUNT_HEADER))
  const limitMax = parseInt(response.headers.get(FEATURE_LIMIT_MAX_HEADER))

  // if headers being passed in can not be coerced into integers, we will not be returning limits
  // as a result we will not be setting limits either.
  if (isNaN(limitCount) && isNaN(limitMax)) return null

  return { limitCount, limitMax }
}

function createQueryStringUrl(baseUrl, params) {
  const qs = Object.keys(params)
    .filter(k => !!params[k] || params[k] === 0) // 0 is a valid value
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  if (qs) {
    return `${baseUrl}?${qs}`
  }

  return baseUrl
}

export {
  makeRequest,
  extractLimitHeaders,
  createQueryStringUrl,
  get,
  post,
  put,
  destroy,
  patch
}
